import React, { useEffect, useRef, useState } from 'react';
import jsQR from 'jsqr';

import './App.css';

const App = () => {
  const [cameraPermission, setCameraPermission] = useState(null);
  const [qrCodeVerificationData, setQrCodeVerificationData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const requestCameraPermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
        setCameraPermission(true);
      } catch (error) {
        console.error(error);
        setCameraPermission(false);
      }
    };

    requestCameraPermission();
  }, []);

  const validateQRCode = async (qrCode) => {
    try {
      // Send decoded token to server for validation
      fetch('https://snbl0d36ig.execute-api.me-south-1.amazonaws.com/prod/verify_invite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token: qrCode })
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setQrCodeVerificationData(data);
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const scanQRCode = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (cameraPermission === false || !video || video.videoWidth === 0 || video.videoHeight === 0) {
      return requestAnimationFrame(scanQRCode);
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);

    const imageData = canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height);

    const code = jsQR(imageData.data, imageData.width, imageData.height, {
      inversionAttempts: 'dontInvert',
    });

    if (code) {
      console.log(code.data);

      if (!isLoading) {
        setIsLoading(true);
        validateQRCode(code.data);
      }
    }

    requestAnimationFrame(scanQRCode);
  };

  useEffect(() => {
    console.log("im running.");

    const refreshVideo = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
      console.log("refresh Video running.");
      console.log(stream);
      console.log(videoRef);
      if (videoRef.current && stream) {
        videoRef.current.srcObject = stream;
      }

      if (videoRef.current) {
        videoRef.current.addEventListener('loadedmetadata', () => {
          requestAnimationFrame(scanQRCode);
        });
      }
    }

    if (cameraPermission === true) {
      refreshVideo();
    }
    
  }, [cameraPermission]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setQrCodeVerificationData(null);
      setIsLoading(false);
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [qrCodeVerificationData]);

  if (cameraPermission === null) {
    return <div>Loading...</div>;
  } else if (cameraPermission === false) {
    return <div>Camera permission not granted</div>;
  } else {
    return (
      <div className="video-container">
        <video ref={videoRef} autoPlay playsInline muted />
        <canvas ref={canvasRef} style={{ display: 'none' }} />
        <div className="page-header">
          {/* <img src="/path/to/image.png" alt="Header Image" /> */}
          <p><b>Da'wa Invites</b> - Point the camera at the invite QR code to verify it.</p>
        </div>
        {qrCodeVerificationData && (
          <div className="verification-result-container">
            <p>Verification result: {" "}
            <span
              style={{
                color: qrCodeVerificationData.isValid ? "green" : "red"
              }}
            >
              {qrCodeVerificationData.isValid ? "Valid" : "Invalid"}
            </span>
            </p>
          </div>
        )}
        {isLoading && !qrCodeVerificationData && (
          <div className="verification-result-container">
            <p>Verifying invitation...</p>
          </div>
        )}
      </div>
    );
  }
};

export default App;